import moment from 'moment';
import { handlePushResponse, constructPushResponse } from '../utilities/handle-push-response';
import PostmongerStore from '../utilities/postmonger';
import I18n from '../utilities/i18n';
import fetchOptions from '../constants/fetch-options';
import { hasProperty } from '../utilities/helper';

const i18n = I18n(PostmongerStore.cultureCode);
const BASE_URL = '/fuelapi/push/v1';
const BASE_URL_INTERNAL = '/fuelapi/push-internal/v1';

moment.locale(i18n.getMomentLocale());

const createMessage = (body) => {
	let url = `${BASE_URL_INTERNAL}`;

	// api specific endpoint
	url += '/message';

	return fetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'X-Csrf-Token': window.APPCORE_BROWSER_CONFIG.csrfToken,
			'Content-Type': 'application/json'
		}
	})
		.then(handlePushResponse)
		.then((data) => {
			if (!data || (!hasProperty(data, 'id') && !hasProperty(data, 'errors') && !hasProperty(data, 'validationErrors'))) {
				return {};
			}
			return data;
		})
		.then((data) => {
			if (!data) {
				return {};
			}
			return constructPushResponse(data, 'id', i18n);
		});
};

const sendTriggeredMessage = (triggeredSendType, messageId, body) => {
	let url = `${BASE_URL}`;

	// api specific endpoint
	url += `/${triggeredSendType}/${messageId}/send`;

	return fetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'X-Csrf-Token': window.APPCORE_BROWSER_CONFIG.csrfToken,
			'Content-Type': 'application/json'
		}
	})
		.then(handlePushResponse)
		.then((data) => {
			if (!data || (!hasProperty(data, 'tokenId') && !hasProperty(data, 'errors') && !hasProperty(data, 'validationErrors'))) {
				return {};
			}
			return data;
		})
		.then((data) => {
			if (!data) {
				return {};
			}
			return constructPushResponse(data, 'tokenId', i18n);
		});
};

const getTriggeredMessageStatus = (triggeredSendType, messageId, deliveryToken) => {
	let url = `${BASE_URL}`;

	// api specific endpoint
	url += `/${triggeredSendType}/${messageId}/deliveries/${deliveryToken}`;

	return fetch(url, fetchOptions)
		.then(handlePushResponse)
		.then((data) => {
			if (!data || (!hasProperty(data, 'messageId') && !hasProperty(data, 'errors') && !hasProperty(data, 'validationErrors'))) {
				return {};
			}
			return data;
		})
		.then((data) => {
			if (!data) {
				return {};
			}
			return constructPushResponse(data, 'messageId', i18n);
		});
};

export { createMessage, sendTriggeredMessage, getTriggeredMessageStatus };
