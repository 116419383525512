export const STEPS = {
	TEST_METHOD: 'TEST_METHOD',
	TEST_INPUT: 'TEST_INPUT',
	SELECT_DEVICE: 'SELECT_DEVICE',
	TEST_RESULT: 'TEST_RESULT'
};

export const CONTACT_ID = {
	UNSELECTED: 'UNSELECTED',
	CONTACT_KEY: 'CONTACT_KEY',
	DEVICE_ID: 'DEVICE_ID',
	SYSTEM_TOKEN: 'SYSTEM_TOKEN',
	CONTACT_LIST: 'CONTACT_LIST'
};

export const AUDIENCE_TYPE = {
	CONTACT_LIST: 'CONTACT_LIST',
	DATA_EXTENSION: 'DATA_EXTENSION'
};
