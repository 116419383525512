import React from 'react';
import isFunction from 'lodash.isfunction';
import Button from '@salesforce/design-system-react/components/button';
import ReactDOM from 'react-dom';

const handleCategoryClick = (event, props) => {
	if (!props.item.url) {
		event.preventDefault();
	}

	if (isFunction(props.onCategorySelect)) {
		props.onCategorySelect(event, {
			item: props.item
		});
	}
};

const handleCustomObjectClick = (event, props) => {
	if (!props.item.url) {
		event.preventDefault();
	}

	if (isFunction(props.onCustomObjectSelect)) {
		props.onCustomObjectSelect(event, {
			item: props.item
		});
	}
};

class CustomItem extends React.Component {
	constructor (props) {
		super(props);
		this.props = props;
		this.state = {
			isButtonOpen: false
		};
	}

	onClickHandler = (event) => {
		if (!this.props.item.hasChildren) {
			event.preventDefault();
			return;
		}
		const nestedList = event.target.closest('li').querySelector('.nested-list');
		if (nestedList.innerHTML.length !== 0) {
			ReactDOM.render('', nestedList);
		} else {
			handleCategoryClick(event, this.props);
		}
		this.setState((prev) => ({
			isButtonOpen: !prev.isButtonOpen
		}));
	};

	render () {
		let iconName;
		if (this.props.item.hasChildren) {
			iconName = this.state.isButtonOpen ? 'spinner' : 'open_folder';
		}	else {
			iconName = 'display_text';
		}

		return (
			<li
				className="slds-nav-vertical__item"
				id={`list-${this.props.item.id}`}
			>
				<div className="list-nav-content">
					<Button
						assistiveText={{ icon: 'Icon Bare Small' }}
						iconCategory="utility"
						iconName={iconName}
						iconSize="small"
						onClick={this.onClickHandler}
						variant="icon"
					/>
					<a
						data-id={this.props.item.id}
						href={this.props.item.url || '#'}
						className="slds-nav-vertical__action"
						aria-describedby={this.props.categoryId}
						onClick={(event) => {
							handleCustomObjectClick(event, this.props);
						}}
					>
						{this.props.item.label}
					</a>
				</div>
				<div className="nested-list" />
			</li>
		);
	}
}

export default CustomItem;
