import moment from 'moment';
import fetchOptions from '../constants/fetch-options';
import { hasProperty } from '../utilities/helper';
import PostmongerStore from '../utilities/postmonger';
import I18n from '../utilities/i18n';
import { handlePushResponse } from '../utilities/handle-push-response';

const i18n = I18n(PostmongerStore.cultureCode);
const BASE_URL = '/fuelapi';

moment.locale(i18n.getMomentLocale());

const getKVObjFromArray =  (obj) => {
	const item = {};
	obj.forEach((ob) => {
		if ('definitionKey' in ob && 'innerValue' in ob) {
			item[ob.definitionKey] = ob.innerValue;
		}
	});
	return item;
};

const fieldIsNotNullOrEmpty = (field) => field !== undefined && field.value !== undefined && field.value.length !== 0;

const fetchAudienceLists = (maxCount = 0) => {
	let url = `${BASE_URL}`;

	// api specific endpoint
	url += '/contacts/v1/audiences/data';

	url += '?encoding=false&$pagesize=100&$page=1&sortby=name%20ASC&filterby=definitionIsActive%20eq%20true%20and%20channel%20eq%20PUSH';

	return fetch(url, fetchOptions).then(handlePushResponse).then((data) => {
		if (!data || (!hasProperty(data, 'items') && !hasProperty(data, 'errorcode'))) {
			return {};
		}
		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		if ('errorcode' in data) {
			return {
				err: data.message,
				items: []
			};
		}

		const contactLists = [];
		data.items.forEach((list) => {
			if (list.contactCount !== 0) {
				if (maxCount !== 0 && list.contactCount > maxCount) {
					return;
				}
				contactLists.push({
					id: list.id,
					key: list.key,
					type: 'Contact List',
					name: list.name,
					count: list.contactCount,
					createdDate: moment(list.createdDate).format('lll'),
					lastUpdated: moment(list.lastUpdatedDate).format('lll')
				});
			}
		});

		return {
			items: contactLists
		};
	});
};

const fetchContactsByContactKey = (contactKey) => {
	let url = `${BASE_URL}`;

	// api specific endpoint
	url += `/contacts-internal/v1/contacts/${contactKey}/attributesets/name:MobilePush%20Demographics`;

	// query params
	url += '?$pagesize=1000&$page=1';

	return fetch(url, fetchOptions).then(handlePushResponse).then((data) => {
		if (!data || (!hasProperty(data, 'items') && !hasProperty(data, 'errorcode'))) {
			return {};
		}
		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		if ('errorcode' in data) {
			return {
				err: data.message,
				items: []
			};
		}

		const items = [];
		data.items.forEach((item) => {
			const values = getKVObjFromArray(item.values);
			items.push({
				id: values.SystemToken,
				deviceId: values.PushDeviceID,
				systemToken: values.SystemToken,
				platform: values.Platform,
				createdDate: moment(values.CreatedDate).format('lll'),
				modifiedDate: moment(values.ModifiedDate).format('lll')
			});
		});

		return {
			items: items
		};
	});
};

const fetchContactKeysForDeviceId = (deviceId) => {
	let url = `${BASE_URL}`;

	// api specific endpoint
	url += '/contacts-internal/v1/addresses/search';

	// query params
	url += '?$pageSize=100&$page=1';

	const body = {
		queryFilter: {
			hasCriteria: true,
			rootExpressionSet: {
				expressions: [
					{
						customerDataDefinitionID: 101,
						operator: 'Equal',
						values: [deviceId]
					},
					{
						customerDataDefinitionID: 104,
						operator: 'Equal',
						values: [
							'PUSH'
						]
					}
				]
			}
		}
	};

	return fetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'X-Csrf-Token': window.APPCORE_BROWSER_CONFIG.csrfToken,
			'Content-Type': 'application/json'
		}
	}).then(handlePushResponse).then((data) => {
		if (!data || (!hasProperty(data, 'addresses') && !hasProperty(data, 'errorcode'))) {
			return {};
		}
		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		if ('errorcode' in data) {
			data.contactKeys = [];
			return data;
		}

		const { addresses } = data;
		const contactKeys = [];

		if (addresses !== undefined && addresses.length > 0) {
			addresses.forEach((address) => {
				if (fieldIsNotNullOrEmpty(address.addressID)
					&& address.addressID.value === deviceId
					&& fieldIsNotNullOrEmpty(address.contactKey) !== undefined) {
					contactKeys.push(address.contactKey.value);
				}
			});
		}

		return { contactKeys: Array.from(new Set(contactKeys)) };
	});
};

const fetchSystemTokenForContactKey = (contactKey) => {
	let url = `${BASE_URL}`;

	// api specific endpoint
	url += `/contacts-internal/v1/contacts/${encodeURIComponent(contactKey)}/attributesets/name:MobilePush%20Demographics`;

	// query params
	url += '?$pagesize=1000&$page=1';

	return fetch(url, fetchOptions).then(handlePushResponse).then((data) => {
		if (!data || (!hasProperty(data, 'items') && !hasProperty(data, 'errorcode'))) {
			return {};
		}
		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		if ('errorcode' in data) {
			data.items = [];
			return data;
		}

		return data.items;
	});
};

export { fetchAudienceLists, fetchContactsByContactKey, fetchContactKeysForDeviceId, fetchSystemTokenForContactKey };
