import React from 'react';

import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import TestMethodContent from './test-method';
import TestInputContent from './test-input';
import { CONTACT_ID, STEPS } from './TestSendConstants';
import ProgressFooter from './progressFooter';
import SelectDevicesContent from './select-devices';
import TestResultContent from './test-results';

/* eslint-disable no-else-return */
/* eslint-disable  react/no-unused-class-component-methods */

export class TestSends extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			currentStep: STEPS.TEST_METHOD
		};
		this.contactId = CONTACT_ID.UNSELECTED;
		this.testMethodRef = React.createRef();
		this.testInputRef = React.createRef();
		this.testResultsRef = React.createRef();
		this.footer = React.createRef();
		this.appPayload = this.props.appPayload;
		this.msgPayload = this.props.msgPayload;
		this.i18n = this.props.i18n;
		this.onHeaderBackButtonClicked = this.props.onHeaderBackButtonClicked;
	}

	testMethodClickHandler = (event) => {
		if (event.target.id.endsWith('choice')) {
			switch (event.target.id) {
				case 'contact-list-choice':
					this.contactId = CONTACT_ID.CONTACT_LIST;
					break;
				case 'system-token-choice':
					this.contactId = CONTACT_ID.SYSTEM_TOKEN;
					break;
				case 'device-id-choice':
					this.contactId = CONTACT_ID.DEVICE_ID;
					break;
				case 'contact-key-choice':
					this.contactId = CONTACT_ID.CONTACT_KEY;
					break;
				default:
			}
			this.testMethodRef.current.updateContactId(this.contactId);
			this.footer.current.handleSelectDeviceStep(this.contactId);
			this.footer.current.updateFooterState({
				nextButtonEnabled: true
			});
		}
	};

	headerText () {
		if (this.state.currentStep === STEPS.TEST_METHOD) {
			return this.i18n.get('test_send_header_select_identifier');
		}	else if (this.state.currentStep === STEPS.TEST_INPUT) {
			if (this.contactId === CONTACT_ID.SYSTEM_TOKEN) {
				return this.i18n.get('test_send_header_system_token');
			} else if (this.contactId === CONTACT_ID.DEVICE_ID) {
				return this.i18n.get('test_send_header_device_id');
			} else if (this.contactId === CONTACT_ID.CONTACT_KEY) {
				return this.i18n.get('test_send_header_contact_key');
			} else {
				return this.i18n.get('test_send_header_select_audience');
			}
		} else if (this.state.currentStep === STEPS.SELECT_DEVICE) {
			return this.i18n.get('test_send_header_select_devices');
		}	else {
			return this.i18n.get('test_send_header_results');
		}
	}

	updateCurrentStep = (selectedStep) => {
		this.setState({
			currentStep: selectedStep
		});
	};

	testInputHandler = (event) => {
		if (event.target.value && event.target.value.trim()) {
			this.testInputValue = event.target.value.trim();
			this.footer.current.updateFooterState({
				nextButtonEnabled: true
			});
		} else {
			this.footer.current.updateFooterState({
				nextButtonEnabled: false
			});
		}
	};

	contactListSelectedOption = (selected) => {
		if (selected && selected.length > 0) {
			this.selectedContactList = selected[0];
			this.footer.current.updateFooterState({
				nextButtonEnabled: true
			});
		}
	};

	deSelectedOption = (selected) => {
		if (selected && selected.length > 0) {
			this.selectedDE = selected[0];
			this.footer.current.updateFooterState({
				nextButtonEnabled: true
			});
		}
	};

	selectDevicesOptions = (selected) => {
		if (selected && selected.length > 0) {
			this.selectedDevices = selected;
			this.footer.current.updateFooterState({
				nextButtonEnabled: true
			});
		}	else {
			this.selectedDevices = null;
			this.footer.current.updateFooterState({
				nextButtonEnabled: false
			});
		}
	};

	handleNextButton = () => {
		let nextStep;
		switch (this.state.currentStep) {
			case STEPS.TEST_METHOD:
				nextStep = STEPS.TEST_INPUT;
				this.footer.current.updateFooterState({
					backButtonEnabled: true,
					nextButtonEnabled: !!this.testInputValue,
					completedSteps: this.footer.current.state.steps.slice(0, 1),
					disabledSteps: this.footer.current.state.steps.slice(1),
					selectedStep: this.footer.current.state.steps[1],
					nextButtonText: this.i18n.get('test_send_footer_next'),
					hideBackButton: false
				});
				break;
			case STEPS.TEST_INPUT:
				if (this.contactId === CONTACT_ID.CONTACT_KEY) {
					nextStep = STEPS.SELECT_DEVICE;
					this.footer.current.updateFooterState({
						backButtonEnabled: true,
						nextButtonEnabled: false,
						completedSteps: this.footer.current.state.steps.slice(0, 2),
						disabledSteps: this.footer.current.state.steps.slice(2),
						selectedStep: this.footer.current.state.steps[2],
						nextButtonText: this.i18n.get('test_send_footer_next'),
						hideBackButton: false
					});
				} else {
					if (this.contactId === CONTACT_ID.DEVICE_ID) {
						this.testInputRef.current.fetchSystemTokenForDeviceId(this.testInputValue);
					}
					nextStep = STEPS.TEST_RESULT;
					this.footer.current.updateFooterState({
						backButtonEnabled: true,
						nextButtonEnabled: false,
						nextButtonText: this.i18n.get('test_send_footer_done'),
						completedSteps: this.footer.current.state.steps.slice(0, 2),
						disabledSteps: this.footer.current.state.steps.slice(2),
						selectedStep: this.footer.current.state.steps[2],
						hideBackButton: false
					});
				}
				break;
			case STEPS.SELECT_DEVICE:
				nextStep = STEPS.TEST_RESULT;
				this.footer.current.updateFooterState({
					backButtonEnabled: true,
					nextButtonEnabled: false,
					nextButtonText: this.i18n.get('test_send_footer_done'),
					completedSteps: this.footer.current.state.steps.slice(0, 3),
					disabledSteps: this.footer.current.state.steps.slice(3),
					selectedStep: this.footer.current.state.steps[3],
					hideBackButton: false
				});
				break;
			case STEPS.TEST_RESULT:
				nextStep = STEPS.TEST_RESULT;
				this.onHeaderBackButtonClicked();
				break;
			default:
		}
		this.updateCurrentStep(nextStep);
	};

	markFooterComplete = () => {
		if (this.testResultsRef.current) {
			this.footer.current.updateFooterState({
				backButtonEnabled: true,
				nextButtonEnabled: true,
				nextButtonText: this.i18n.get('test_send_footer_done'),
				completedSteps: this.footer.current.state.steps,
				disabledSteps: [],
				selectedStep: this.footer.current.state.steps.slice(-1)[0] // last step
			});
		}
	};

	handleBackButton = () => {
		switch (this.state.currentStep) {
			case STEPS.TEST_METHOD:
				break;
			case STEPS.TEST_INPUT:
				this.updateCurrentStep(STEPS.TEST_METHOD);
				this.footer.current.updateFooterState({
					backButtonEnabled: false,
					nextButtonEnabled: true,
					completedSteps: [],
					disabledSteps: this.footer.current.state.steps.slice(0),
					selectedStep: this.footer.current.state.steps[0],
					nextButtonText: this.i18n.get('test_send_footer_next'),
					hideBackButton: true
				});
				break;
			case STEPS.SELECT_DEVICE:
				this.updateCurrentStep(STEPS.TEST_INPUT);
				this.footer.current.updateFooterState({
					backButtonEnabled: true,
					nextButtonEnabled: !!this.testInputValue,
					completedSteps: this.footer.current.state.steps.slice(0, 1),
					disabledSteps: this.footer.current.state.steps.slice(1),
					selectedStep: this.footer.current.state.steps[1],
					nextButtonText: this.i18n.get('test_send_footer_next'),
					hideBackButton: false
				});
				break;
			case STEPS.TEST_RESULT:
				if (this.contactId === CONTACT_ID.CONTACT_KEY) {
					this.updateCurrentStep(STEPS.SELECT_DEVICE);
					this.footer.current.updateFooterState({
						backButtonEnabled: true,
						nextButtonEnabled: false,
						completedSteps: this.footer.current.state.steps.slice(0, 2),
						disabledSteps: this.footer.current.state.steps.slice(2),
						selectedStep: this.footer.current.state.steps[2],
						nextButtonText: this.i18n.get('test_send_footer_next'),
						hideBackButton: false
					});
				}	else {
					this.updateCurrentStep(STEPS.TEST_INPUT);
					this.footer.current.updateFooterState({
						backButtonEnabled: true,
						nextButtonEnabled: !!this.testInputValue,
						completedSteps: this.footer.current.state.steps.slice(0, 1),
						disabledSteps: this.footer.current.state.steps.slice(1),
						selectedStep: this.footer.current.state.steps[1],
						nextButtonText: this.i18n.get('test_send_footer_next'),
						hideBackButton: false
					});
				}
				break;
			default:
		}
	};

	updateSystemTokenFromDeviceId = (systemTokenFromDeviceId, err) => {
		if (err) {
			this.testResultsRef.current.err = err;
		} else if (this.testResultsRef.current) {
			this.testResultsRef.current.systemTokenFromDeviceId = systemTokenFromDeviceId;
			this.testResultsRef.current.testSend();
		}
	};

	renderContent = () => {
		switch (this.state.currentStep) {
			case STEPS.TEST_METHOD:
				this.testInputValue = '';
				return (
					<TestMethodContent
						testMethodClickHandler={this.testMethodClickHandler}
						contactId={this.contactId}
						ref={this.testMethodRef}
						i18n={this.i18n}
					/>
				);
			case STEPS.TEST_INPUT:
				return (
					<TestInputContent
						contactId={this.contactId}
						testInputHandler={this.testInputHandler}
						inputValue={this.testInputValue ? this.testInputValue : null}
						contactListSelectedOption={this.contactListSelectedOption}
						deSelectedOption={this.deSelectedOption}
						appId={this.appPayload.id}
						updateFooterButton={() => this.footer.current.updateFooterState({ nextButtonEnabled: false })}
						updateSystemTokenFromDeviceId={this.updateSystemTokenFromDeviceId}
						ref={this.testInputRef}
						i18n={this.i18n}
					/>
				);
			case STEPS.SELECT_DEVICE:
				return (
					<SelectDevicesContent
						contactKey={this.testInputValue}
						selectedOption={this.selectDevicesOptions}
						i18n={this.i18n}
					/>
				);
			case STEPS.TEST_RESULT:
				return (
					<TestResultContent
						contactId={this.contactId}
						audienceType={this.contactId === CONTACT_ID.CONTACT_LIST ? this.testInputRef.current.state.audienceType : null}
						testInputValue={this.testInputValue}
						selectedContactList={this.selectedContactList ? this.selectedContactList : null}
						selectedDevices={this.selectedDevices ? this.selectedDevices : null}
						selectedDE={this.selectedDE ? this.selectedDE : null}
						markFooterComplete={this.markFooterComplete}
						appPayload={this.appPayload}
						msgPayload={this.msgPayload}
						ref={this.testResultsRef}
						i18n={this.i18n}
					/>
				);
			default: return '';
		}
	};

	render () {
		return (
			<IconSettings iconPath="/assets/icons">
				<div className="test-sends-content-wrapper">
					<div className="slds-modal__header progress-modal-header">
						<div>
							<h1 className="slds-text-heading_medium" id="progress-modal-title">{this.headerText()}</h1>
						</div>
					</div>
					<div className="progress-modal-content">
						{this.renderContent()}
					</div>
					<ProgressFooter
						ref={this.footer}
						contactId={this.contactId}
						updateCurrentStep={this.updateCurrentStep}
						handleNextButton={this.handleNextButton}
						handleBackButton={this.handleBackButton}
						i18n={this.i18n}
					/>
				</div>
			</IconSettings>
		);
	}
}

export default TestSends;
