import { hasProperty } from './helper';

/**
 * Utility to parse app object to get the ViewTemplate category name if defined within the app
 * @param {object} appDetails, the selected app details
 * @returns the ViewTemplate category name if defined at app level
 */
const getTemplateCategory = (appDetails) => {
	try {
		if (hasProperty(appDetails, 'categoryList')) {
			if (appDetails.categoryList.length) {
				const viewTemplateCategory = appDetails.categoryList.find((category) => category.categoryType === 'ViewTemplate');
				return viewTemplateCategory?.name?.trim();
			}
		}
		return '';
	} catch (e) {
		console.log(e);
		return '';
	}
};

export default getTemplateCategory;
