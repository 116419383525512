import React from 'react';
import CustomItem from './customItem';

class CustomVerticalNavigation extends React.Component {
	render () {
		return this.props.categories.map((category) => {
			const categoryId = `${this.props.id}-${category.id}`;
			return (
				<ul key={categoryId} id={this.props.id}>
					{category.items.map((item) => (
						<CustomItem
							key={item.id}
							item={item}
							categoryId={categoryId}
							onCategorySelect={this.props.onCategorySelect}
							onCustomObjectSelect={this.props.onCustomObjectSelect}
						/>
					))}
				</ul>
			);
		});
	}
}

export default CustomVerticalNavigation;
