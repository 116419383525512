import React from 'react';
import PropTypes from 'prop-types';

function AppTemplateCategory (props) {
	const { i18n, categoryName } = props;
	return (
		<div className="app-template-category-container">
			<div className="container-heading">{i18n.get('app-template-category')}</div>
			{
				categoryName ? <div>{categoryName}</div> : (
					<div className="no-category">
						(
						{i18n.get('app-template-category-not-defined')}
						)
					</div>
				)
			}
		</div>
	);
}

AppTemplateCategory.propTypes = {
	i18n: PropTypes.object.isRequired,
	categoryName: PropTypes.string
};

export default AppTemplateCategory;
