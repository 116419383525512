import React from 'react';

import SplitView  from '@salesforce/design-system-react/components/split-view/index';
import DETree from './deTree';
import { fetchCategories, fetchCustomObjects } from '../../../actions/dataExtension';
import SelectOptionTable from './select-option-table';

class DESelector extends React.Component {
	constructor (props) {
		super(props);
		this.deTree = React.createRef();
		this.deTable = React.createRef();
		this.i18n = this.props.i18n;
		this.columns = [
			{
				columnName: this.i18n.get('test_send_data_extension_column_name'),
				columnProperty: 'name',
				sortable: true,
				width: '7rem',
				primary: true
			},
			{
				columnName: this.i18n.get('test_send_data_extension_column_population'),
				columnProperty: 'population',
				sortable: true,
				width: '4rem'
			},
			{
				columnName: this.i18n.get('test_send_data_extension_column_date_modified'),
				columnProperty: 'modifiedDate',
				sortable: true,
				width: '6rem'
			},
			{
				columnName: this.i18n.get('test_send_data_extension_column_created_by'),
				columnProperty: 'createdByName',
				sortable: true,
				width: '6rem'
			}
		];
		this.state = {
			isOpen: typeof props.isOpen === 'boolean' ? props.isOpen : true,
			tableItems: [],
			init: false,
			selectPrompt: true
		};
		this.apiCache = {
			category: {},
			customObject: {}
		};
		this.selectedOption = this.props.selectedOption;
	}

	fetchCategoryApi = (id) => {
		if (id in this.apiCache.category) {
			if (id === '0' && this.deTree) {
				this.deTree.current.updateTree(this.apiCache.category[id]);
			}	else {
				this.deTree.current.updateNestedTree(this.apiCache.category[id]);
			}
			return;
		}

		fetchCategories(id).then((categories) => {
			this.apiCache.category[id] = [{
				id: id,
				items: categories
			}];
			if (id === '0' && this.deTree) {
				this.deTree.current.updateTree(this.apiCache.category[id]);
			}	else {
				this.deTree.current.updateNestedTree(this.apiCache.category[id]);
			}
		});
	};

	fetchCustomObjectsApi = (id) => {
		if (id in this.apiCache.customObject && this.deTable.current) {
			this.setState({
				tableItems: this.apiCache.customObject[id],
				init: false,
				selectPrompt: false
			});
			return;
		}

		this.setState({
			tableItems: [],
			init: true,
			selectPrompt: false
		});

		fetchCustomObjects(id).then((categories) => {
			this.apiCache.customObject[id] = categories;
			if (this.deTable.current) {
				this.setState({
					tableItems: this.apiCache.customObject[id],
					init: false,
					selectPrompt: false
				});
			}
		});
	};

	render () {
		return (
			<div className="de-container">
				<SplitView
					events={{
						onClose: () => {
							this.setState({ isOpen: false });
						},
						onOpen: () => {
							this.setState({ isOpen: true });
						}
					}}
					id="de-split-view"
					isOpen={this.state.isOpen}
					master={(
						<DETree
							ref={this.deTree}
							fetchCategoryApi={this.fetchCategoryApi}
							fetchCustomObjectsApi={this.fetchCustomObjectsApi}
							apiCache={this.apiCache}
						/>
					)}
					detail={(
						<SelectOptionTable
							ref={this.deTable}
							columnHeaders={this.columns}
							items={this.state.tableItems}
							id="de-selector-table"
							showSelectPrompt={this.state.selectPrompt}
							callbackValue={this.selectedOption}
							loader={this.state.init}
							i18n={this.props.i18n}
						/>
					)}
				/>
			</div>
		);
	}
}

export default DESelector;
