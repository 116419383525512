import React from 'react';
import Button from '@salesforce/design-system-react/components/button';
import Icon from '@salesforce/design-system-react/components/icon';
import DynamicIcon from '@salesforce/design-system-react/components/dynamic-icon';
import ArticleItem from './articleItem';

class ResultItem extends React.Component {
	constructor (props) {
		super(props);
		this.props = props;
		this.state = {
			isOpen: false
		};
		this.retryButtonHandler = this.props.retryButtonHandler;
	}

	getItemContainerClass = (props) => {
		let className = 'slds-timeline__item_expandable ';
		if (props.last) {
			className += 'slds-timeline__item_last ';
		}

		if (props.queued) {
			className += 'slds-timeline__item_queued ';
		} else if (props.success) {
			className += 'slds-timeline__item_success ';
		} else {
			className += 'slds-timeline__item_failure ';
		}

		if (props.dummy) {
			className += 'slds-timeline__item_progress dummy-opacity ';
		}

		if (this.state.isOpen) {
			className += 'slds-is-open';
		}
		return className;
	};

	getIcon = (props) => {
		let className;
		let name;
		switch (props.iconType) {
			case 'success':
				className = 'slds-icon-standard-task2';
				name = 'task2';
				break;
			case 'failure':
				className = 'slds-icon-standard-incident';
				name = 'first_non_empty';
				break;
			case 'queued':
				className = 'slds-icon-custom-custom3';
				name = 'search';
				break;
			case 'progress':
				className = 'slds-icon-standard-generic-loading';
				name = 'business_hours';
				break;
			default:
		}
		return (
			<Icon
				assistiveText={{ label: props.taskAssistiveText }}
				className={className}
				category="standard"
				name={name}
				size="small"
			/>
		);
	};

	getItemClass = (props) => (props.hideExpandButton ? 'slds-media hideExpandButton' : 'slds-media');

	toggleButtonHandler = () => {
		this.setState((prev) => ({
			isOpen: !prev.isOpen
		}));
	};

	render () {
		let articleItems = '';

		if (this.props.articleListMap.length > 0) {
			articleItems = this.props.articleListMap.map((articleItem) => (
				<ArticleItem
					item={articleItem.key}
					value={articleItem.value}
					key={articleItem.key}
				/>
			));
		}

		return (
			<li
				key={this.props.itemId}
			>
				<div className={this.getItemContainerClass(this.props)} id={this.props.itemId}>
					<span className="slds-assistive-text">{this.props.taskAssistiveText}</span>
					<div className={this.getItemClass(this.props)}>
						<div className="slds-media__figure">
							<Button
								assistiveText={{ icon: this.props.toggleInfoBtnTitle }}
								iconCategory="utility"
								iconName={this.state.isOpen ? 'chevrondown' : 'chevronright'}
								onClick={this.toggleButtonHandler}
								variant="icon"
								className={this.props.hideExpandButton ? 'toggle-info-btn hidden' : 'toggle-info-btn'}
							/>
							{this.getIcon(this.props)}
						</div>
						<div className="slds-media__body">
							<div className="slds-grid slds-grid_align-spread slds-timeline__trigger timeline-view-headers">
								<div className="slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space">
									<h3 className="slds-truncate main-title"><strong>{this.props.mainHeading}</strong></h3>
									<Button
										assistiveText={{ icon: 'retry button assistive text' }}
										iconCategory="utility"
										iconName="refresh"
										size="small"
										onClick={this.retryButtonHandler}
										variant="icon"
										className={!this.props.showRetry ? 'hidden trigger-api-retry' : 'trigger-api-retry'}
									/>
								</div>
							</div>
							<p className="slds-m-horizontal_xx-small sub-title">
								{this.props.subHeading}
							</p>
							{this.props.dummy ? (
								<div className="pre-loader">
									<DynamicIcon title="User is typing" variant="typing" />
								</div>
							) : ''}
							<article
								className={`slds-box slds-timeline__item_details slds-theme_shade slds-m-top_x-small slds-m-horizontal_xx-small ${!this.state.isOpen ? 'hidden' : ''}`}
								id={`${this.props.toggleInfoBtnTitle}-article`}
							>
								{articleItems.length > 0
									? (
										<ul className="slds-list_horizontal slds-wrap article-ul">
											{articleItems}
										</ul>
									) : ''}
							</article>
						</div>
					</div>
				</div>
			</li>
		);
	}
}

export default ResultItem;
