import checkResponseStatus from '../utilities/check-response-status';
import fetchOptions from '../constants/fetch-options';
import { hasProperty } from '../utilities/helper';
import PostmongerStore from '../utilities/postmonger';

export default function fetchSettings () {
	const url = '/fuelapi/push-internal/v1/setting';

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data || !hasProperty(data, 'items')) {
			return {};
		}

		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		data.items.forEach((item) => {
			PostmongerStore.featureFlag[item.settingKey] = item.settingValue;
		});

		return {
			items: data.items
		};
	});
}
