import React from 'react';
import PropTypes from 'prop-types';

function AssetInteractiveButtons (props) {
	const { i18n, buttons, interactiveNotifications } = props;
	return (
		<div className="asset-interactive-buttons-container">
			{!(interactiveNotifications.length > 0) && <div className="asset-interactive-buttons-heading container-heading">{i18n.get('interactive_notification')}</div>}
			<div className="container-heading">
				{i18n.get('android')}
				<span className="container-helper-text">
					{' '}
					(
					{i18n.get('asset-android-buttons-helper')}
					)
				</span>
			</div>
			
			<div>{buttons.join(', ')}</div>
		</div>
	);
}

AssetInteractiveButtons.propTypes = {
	i18n: PropTypes.object.isRequired,
	interactiveNotifications: PropTypes.array.isRequired,
	buttons: PropTypes.array
};

export default AssetInteractiveButtons;
