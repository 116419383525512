import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function AssetSounds (props) {
	const { i18n, sounds: { androidSound, iOSSound }, setNotificationSound } = props;

	useEffect(() => {
		// set sound value for API processing
		let sound;
		const androidSoundVal = androidSound.value;
		const iOSSoundVal = iOSSound.value;
		if (androidSoundVal === '' || iOSSoundVal === '') {
			sound = 'none';
		} else if (androidSoundVal === 'default' || iOSSoundVal === 'default') {
			sound = 'default';
		} else if (androidSoundVal?.length || iOSSoundVal?.length) {
			sound = 'custom.caf';
		}
		setNotificationSound({ target: { value: sound } });
	}, []);

	return (
		<div className="asset-sounds-container">
			<div className="container-heading">
				{i18n.get('notification_sound')}
			</div>
			<div className="asset-sound-container ios">
				<div className="asset-heading container-heading">{i18n.get('ios')}</div>
				<div className="sound-name">{iOSSound.label}</div>
			</div>
			<div className="asset-sound-container android">
				<div className="asset-heading container-heading">{i18n.get('android')}</div>
				<div className="sound-name">{androidSound.label}</div>
			</div>
		</div>
	);
}

AssetSounds.propTypes = {
	i18n: PropTypes.object.isRequired,
	sounds: PropTypes.object,
	setNotificationSound: PropTypes.func.isRequired
};

export default AssetSounds;
