const handlePushResponse = (response) => response.json();

const constructPushResponse = (response, key, i18n) => {
	if ('errors' in response) {
		const list = [];
		response.errors.forEach((error) => {
			let err = error;
			if (err.startsWith('Token id')) {
				err = i18n.get('test_send_result_item_header_snag');
			}
			list.push({ key: i18n.get('test_send_result_item_header_validation_error'), value: err });
		});
		return {
			errors: list
		};
	}

	if ('validationErrors' in response) {
		const list = [];
		response.validationErrors.forEach((error) => {
			list.push({ key: i18n.get('test_send_result_item_header_validation_error'), value: error.message });
		});
		return {
			errors: list
		};
	}

	if (!(key in response)) {
		return {
			errors: [{ key: i18n.get('test_send_result_item_header_validation_error'), value: i18n.get('test_send_result_item_header_snag') }]
		};
	}

	return response;
};

export { handlePushResponse, constructPushResponse };
