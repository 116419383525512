import React from 'react';
import PropTypes from 'prop-types';

function ArticleItem ({
	item,
	value
}) {
	return (
		<li
			className="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
		>
			<strong><span className="slds-text-title slds-p-bottom_x-small key">{item}</span></strong>
			<span className="slds-text-body_medium slds-truncate value">{value}</span>
		</li>
	);
}

ArticleItem.propTypes = {
	item: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired
};

export default ArticleItem;
