import moment from 'moment';
import checkResponseStatus from '../utilities/check-response-status';
import fetchOptions from '../constants/fetch-options';
import { hasProperty, sanitizeHTML } from '../utilities/helper';
import I18n from '../utilities/i18n';
import PostmongerStore from '../utilities/postmonger';
import getTemplateCategory from '../utilities/template-category';

/**
* @private
* This is just a map to make finding application easier.  It is populated when the applications are fetched.
*/
const applicationIdMap = {};

const getLocaleText = (key) => {
	const { cultureCode } = PostmongerStore;
	const i18n = I18n(cultureCode);

	return i18n.get(key);
};

const getExpiredHTMLText = (isExpired) => {
	if (isExpired) {
		const expired = getLocaleText('expired');

		return `<span class="expired">${expired.toUpperCase()}</span>`;
	}

	return '';
};

const isEnabled = (apnsAuthType, gcmEnabled, fcmFileName, apnsAuthKeyFileName, apnsEnabled) => {
	if (gcmEnabled || !!fcmFileName) {
		return true;
	}

	if (apnsAuthType === 'Token') {
		return !!apnsAuthKeyFileName;
	}

	return apnsEnabled;
};

const isApnsCertExpired = (apnsAuthType, apnsCertificateExpiration) => {
	// If ios sending option is p8,, apns doesn't expire
	if (apnsAuthType === 'Token') {
		return false;
	}

	const apnsExpiration = apnsCertificateExpiration ? moment(apnsCertificateExpiration) : false;
	const current = moment();

	return apnsExpiration ? apnsExpiration.isBefore(current) : false;
};

export const fetchApplications = () => {
	const url = '/fuelapi/push-internal/v1/application?&$pageSize=1000&$page=1&$orderBy=name%20ASC';

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data || !hasProperty(data, 'items')) {
			return {};
		}

		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		const items = [];

		data.items.forEach((item) => {
			const { id, name, apnsAuthType, apnsEnabled, apnsCertificateExpiration, gcmEnabled, fcmFileName, apnsAuthKeyFileName } = item;
			const enabled = isEnabled(apnsAuthType, gcmEnabled, fcmFileName, apnsAuthKeyFileName, apnsEnabled);
			const expired = isApnsCertExpired(apnsAuthType, apnsCertificateExpiration);
			const disabled = !enabled;
			const expiredHTML = expired ? getExpiredHTMLText(expired) : '';
			const nameHTML = disabled ? `<span class="disabled-text">${sanitizeHTML(name)}</span>` : sanitizeHTML(name);
			let { iconUrl } = item;

			iconUrl = ((iconUrl) && (iconUrl.length > 0)) ? iconUrl : '';

			const templateCategoryName = getTemplateCategory(item);

			const application = {
				id,
				name,
				value: expired ? `${expiredHTML} ${nameHTML}` : nameHTML,
				title: name,
				iconUrl,
				disabled,
				expired,
				...(templateCategoryName ? { templateCategoryName } : {})
			};

			items.push(application);
			applicationIdMap[id] = Object.assign({}, application);
		});

		return {
			count: data.count,
			items
		};
	});
};

export const getApplicationById = (id) => applicationIdMap[id] || {};

export const fetchApplicationDetail = (id) => {
	const url = `/fuelapi/push-internal/v1/application/${id}`;

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data) {
			return {};
		}

		return data;
	});
};
