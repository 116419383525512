import Template from './mobile-application.html';
import Combobox from '../../common/combobox';
import { hasProperty } from '../../../utilities/helper';
import { fetchApplicationDetail, getApplicationById } from '../../../actions/applications';
import Alert from '../../common/alert';
import isMessageCarousel from '../../../utilities/check-carousel';
import PostmongerStore from '../../../utilities/postmonger';

export default function (dom, configs, showTestSendButton) {
	const { i18n, applications, sectionKey, isFetching, xt, store, showLoadMask, hideLoadMask } = configs;
	const currentApplication = hasProperty(store[sectionKey], 'application') ? getApplicationById(store[sectionKey].application.id) : undefined;
	const selectedMessageDefinition = store.messageDefinition;
	const isAdvancedPushEnabled = PostmongerStore.featureFlag['RichNotification.PushActivity.Enabled'] === 'true';

	const renderMissingCategoryMessage = (application) => {
		const container = dom.querySelector('#category-alert-container');
		// Show warning if the message contains carousel content but there is no template category defined at app setting level
		if (application && !application.templateCategoryName && isMessageCarousel(selectedMessageDefinition)) {
			const options = {
				type: 'error',
				message: `<div class="missing-category">
					<div class="missing-category-heading">${i18n.get('app-template_category_missing_heading')}</div>
					<div class="missing-category-message">${i18n.get('app-template_category_missing_warning')}</div>
				</div>`
			};
			Alert(container, options);
		} else {
			container.innerHTML = '';
		}
	};

	const renderExpiredMessage = (application) => {
		const container = dom.querySelector('#alert-container');

		if ((application) && (application.expired)) {
			const options = {
				type: 'warning',
				message: i18n.get('expired_application_warning')
			};

			this.alert = new Alert(container, options);
		} else {
			container.innerHTML = '';
		}
	};

	const onFetchSuccess = () => {
		hideLoadMask();
		showTestSendButton();
		xt.enableSpoke('deliveryOptions');
		xt.enableSpoke('advancedOptions');
	};

	const onFetchFail = () => {
		hideLoadMask();
		xt.disableSpoke('deliveryOptions');
		xt.disableSpoke('advancedOptions');
	};

	const saveMobileApplication = (application) => {
		const messageConfigurationStore = store[sectionKey];
		const deliveryOptionsStore = store.deliveryOptions;
		const advancedOptionsStore = store.advancedOptions;

		delete messageConfigurationStore.application;
		delete messageConfigurationStore.payload.application;

		delete deliveryOptionsStore.selectedInteractiveNotification;
		delete deliveryOptionsStore.custom;
		delete deliveryOptionsStore.payload.interactiveNotification;

		delete advancedOptionsStore.customKeys;
		delete advancedOptionsStore.payload.keys;

		messageConfigurationStore.mobileAppDetail = {};
		deliveryOptionsStore.interactiveNotifications = [];

		if ((application) && (application.id)) {
			messageConfigurationStore.payload.application = {
				id: application.id,
				name: application.name
			};

			// Update metadata
			messageConfigurationStore.application = (({ iconUrl, ...others }) => ({ ...others }))(application); // Store application data without iconUrl to reduce payload size

			// Show loader until message detail fetch is completed
			showLoadMask({
				transparent: true
			});

			fetchApplicationDetail(application.id).then((data) => {
				if (!data) {
					// If message detail fetch does not have any data for some reason, remove the loader in order not to block user from moving forward.
					// Delivery options and advanced options should be disabled since they are deeply associated with the application detail data.
					onFetchFail();

					return {};
				}

				messageConfigurationStore.mobileAppDetail = (({ iconUrl, ...others }) => ({ ...others }))(data);

				if (data.categories) {
					deliveryOptionsStore.interactiveNotifications = data.categories.map((category) => ({
						id: category.id,
						label: category.name,
						value: category.name,
						buttons: category.buttons
					}));
				}

				const isRequirementSatisfied = hasProperty(messageConfigurationStore.payload, 'application') && hasProperty(messageConfigurationStore.payload.application, 'id') && hasProperty(messageConfigurationStore.payload.application, 'name') && messageConfigurationStore.payload.application.id.length > 0 && messageConfigurationStore.payload.application.name.length > 0;

				if (isRequirementSatisfied) {
					// When fetch is completed, remove the loader
					onFetchSuccess();
				}

				console.log(store); // Debugging Purpose

				return data;
			}, () => { // On fetch fail
				// If message detail fetch is failed for some reason, remove the loader in order not to block user from moving forward.
				// Delivery options and advanced options should be disabled since they are deeply associated with the application detail data.
				onFetchFail();
			});
		}
	};

	const renderComboBox = () => {
		const container = dom.querySelector('#combobox-container');
		const options = {
			label: i18n.get('mobile_app_selection_dropdown_label'),
			id: 'mobile-app-selection-combobox',
			placeholder: i18n.get('mobile_app_selection_dropdown_placeholder'),
			noOptionsFoundText: 'No applications found',
			selection: currentApplication || {},
			required: true,
			hasIconUrl: true,
			options: applications || [],
			isFetching: isFetching || false
		};
		const combobox = new Combobox(container, options);

		combobox.onSelect = (selectedApplication) => {
			renderExpiredMessage(selectedApplication);
			if (isAdvancedPushEnabled) {
				renderMissingCategoryMessage(selectedApplication);
			}
			saveMobileApplication(selectedApplication);
		};
	};

	const renderHeader = () => {
		const header = i18n.get('mobile_app_selection_heading');

		dom.querySelector('#header-container').innerHTML = header;
	};

	const render = () => {
		dom.innerHTML = Template;
	};

	const initialize = () => {
		render();
		renderHeader();
		renderComboBox();

		if ((currentApplication) && (Object.keys(currentApplication).length !== 0)) {
			renderExpiredMessage(currentApplication);
			if (isAdvancedPushEnabled) {
				renderMissingCategoryMessage(currentApplication);
			}
		}
	};

	initialize();
}
