/* eslint-disable react/no-unused-state */

import React from 'react';

import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';

class SelectOptionTable extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			sortColumn: 'name',
			items: this.props.items,
			selection: []
		};
		this.i18n = this.props.i18n;
		this.columns = this.props.columnHeaders;
		this.selectRows = this.props.checkbox ? 'checkbox' : 'radio';
		this.limitSelect = this.props.limitSelect ? this.props.limitSelect : null;
		this.callbackValue = this.props.callbackValue;
		this.sorted = false;
		this.dateTypeSort = ['modifiedDate', 'createdDate', 'lastUpdated'];
	}

	togglePointerEventsForUnselectedInputs = (add) => {
		const inputs = document.querySelectorAll('tbody input[type="checkbox"]:not(:checked)');
		Array.from(inputs).forEach((input) => {
			if (add) {
				input.setAttribute('disabled', 'disabled');
			} else {
				input.removeAttribute('disabled', 'disabled');
			}
		});
	};

	handleChanged = (event, data) => {
		if (this.selectRows === 'checkbox' && this.limitSelect) {
			if (data.selection.length === this.limitSelect) {
				this.togglePointerEventsForUnselectedInputs(true);
			} else {
				this.togglePointerEventsForUnselectedInputs(false);
			}
		}
		this.setState({ selection: data.selection });
		if (this.callbackValue) {
			this.callbackValue(data.selection);
		}
	};

	handleSort = (sortColumn) => {
		const sortProperty = sortColumn.property;
		const { sortDirection } = sortColumn;
		const newState = {
			sortColumn: sortProperty,
			items: [...this.props.items]
		};

		newState.items = newState.items.sort((a, b) => {
			const a1 = a[sortProperty];
			const b1 = b[sortProperty];
			if (this.dateTypeSort.includes(sortProperty)) {
				return (new Date(a1) < new Date(b1) ? -1 : 1) * (sortDirection === 'desc' ? -1 : 1);
			}
			return a1.localeCompare(b1, undefined, { numeric: true }) * (sortDirection === 'desc' ? -1 : 1);
		});
		this.setState(newState);
		this.sorted = true;
	};

	getColumnsRow = (addExtra) => {
		const { columns } = this;
		if (addExtra) {
			columns.unshift({
				columnName: ' ',
				columnProperty: 'dummy',
				sortable: false,
				width: '1.5rem'
			});
		}

		const rendered = columns.map((column) => (
			<DataTableColumn
				isSorted={this.state.sortColumn === column.columnProperty}
				label={column.columnName}
				property={column.columnProperty}
				sortable={column.sortable}
				width={column.width}
				primaryColumn={column.primary ? column.primary : false}
				key={column.columnName}
			/>
		));

		if (addExtra) {
			columns.shift();
		}

		return rendered;
	};

	render () {
		return (
			<div className="select-option-table">
				{this.selectRows === 'checkbox' && this.limitSelect
					? (
						<div
							className={this.state.selection.length === this.limitSelect ? 'contacts-selected-count count-over-limit' : 'contacts-selected-count'}
						>
							{this.i18n.get('test_send_select_devices_of_max').replace('{{selected}}', this.state.selection.length).replace('{{max}}', this.limitSelect)}
						</div>
					) : ''}
				<DataTable
					assistiveText={{
						actionsHeader: 'actions',
						columnSort: 'sort this column',
						columnSortedAscending: 'asc',
						columnSortedDescending: 'desc',
						selectAllRows: 'Select all rows',
						selectRow: 'Select this row'
					}}
					fixedHeader={this.props.loader}
					fixedLayout
					keyboardNavigation
					items={this.sorted ? this.state.items : this.props.items}
					id={this.props.id}
					onRowChange={this.handleChanged}
					onSort={this.handleSort}
					selection={this.state.selection}
					selectRows={this.selectRows}
					hasMore={this.props.loader}
				>
					{this.props.items.length > 0 ? this.getColumnsRow(false) : this.getColumnsRow(true)}
				</DataTable>
				{this.props.showSelectPrompt ? <div className="empty select-an-item">{this.i18n.get('test_send_select_table_select_an_item')}</div> : ''}
				{this.props.err ? <div className="empty select-an-item">{this.i18n.get('test_send_select_table_failed_to_fetch')}</div> : ''}
				{!this.props.loader && !this.props.showSelectPrompt && !this.props.err && this.props.items.length === 0
					? (
						<div className="empty no-items-found">
							{this.i18n.get('test_send_select_table_no_found')}
						</div>
					) : ''}
			</div>
		);
	}
}

export default SelectOptionTable;
