import React from 'react';
import ReactDOM from 'react-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import CustomVerticalNavigation from './customVerticalNavigation';

class DETree extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			tree: []
		};
		this.fetchCategoryApi = props.fetchCategoryApi;
		this.fetchCustomObjectsApi = props.fetchCustomObjectsApi;
		this.apiCache = props.apiCache;
	}

	updateTree = (tree) => { // eslint-disable-line react/no-unused-class-component-methods
		const loader = document.querySelector('.pre-loader');
		if (loader) {
			loader.classList.add('slds-hide');
		}
		this.setState({
			tree: tree
		});
	};

	renderFullTree = () => {
		const queue = ['0'];
		while (queue.length) {
			const currentKey = queue.shift();
			const currentTree = this.apiCache.category[currentKey];
			if (currentKey === '0') {
				this.updateTree(currentTree);
			}	else {
				setTimeout(() => this.updateNestedTree(currentTree), 500);
			}
			currentTree[0].items.forEach((item) => {
				if (item.id in this.apiCache.category) {
					queue.push(item.id);
				}
			});
		}
	};

	componentDidMount () {
		if (this.apiCache.category['0']) {
			this.renderFullTree();
		}	else {
			this.fetchCategoryApi('0');
		}
	}

	onCustomObjectSelect = (event, data) => {
		document.querySelectorAll('.list-nav-content a').forEach((obj) => obj.classList.remove('slds-is-active'));
		this.fetchCustomObjectsApi(data.item.id.toString());
		document.querySelector(`#list-${data.item.id} a`).classList.add('slds-is-active');
	};

	onCategorySelect = (event, data) => {
		this.fetchCategoryApi(data.item.id.toString());
	};

	updateNestedTree = (tree) => { // eslint-disable-line react/no-unused-class-component-methods
		const li = document.querySelector(`#list-${tree[0].id}`);
		ReactDOM.render(
			<IconSettings iconPath="/assets/icons">
				<CustomVerticalNavigation
					id={`deNestedTree-${tree[0].id}`}
					categories={tree}
					// selectedId={this.state.selectedId}
					onCategorySelect={this.onCategorySelect}
					onCustomObjectSelect={this.onCustomObjectSelect}
				/>
			</IconSettings>,
			li.querySelector('.nested-list')
		);

		setTimeout(() => {
			li.querySelector('button svg').innerHTML = '<use href="/assets/icons/utility-sprite/svg/symbols.svg#opened_folder"></use>';
		}, 300);
	};

	render () {
		return (
			<div className="de">
				<CustomVerticalNavigation
					id="deTree"
					categories={this.state.tree}
					onCategorySelect={this.onCategorySelect}
					onCustomObjectSelect={this.onCustomObjectSelect}
				/>
				<Spinner
					size="medium"
					variant="brand"
					assistiveText={{ label: 'Main Frame Loading...' }}
					containerClassName="pre-loader"
				/>
			</div>
		);
	}
}

export default DETree;
