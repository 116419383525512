/* eslint-disable react/no-unused-class-component-methods */

import React from 'react';
import Button from '@salesforce/design-system-react/components/button';
import ProgressIndicator from '@salesforce/design-system-react/components/progress-indicator';
import { CONTACT_ID, STEPS } from './TestSendConstants';

const footerSteps = [
	{ id: 0, label: '' },
	{ id: 1, label: '' },
	{ id: 3, label: '' }
];

class ProgressFooter extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			backButtonEnabled: false,
			nextButtonEnabled: false,
			hideBackButton: true,
			nextButtonText: props.i18n.get('test_send_footer_next'),
			steps: footerSteps,
			completedSteps: [],
			disabledSteps: footerSteps.slice(1, footerSteps.length),
			selectedStep: footerSteps[0]
		};
		this.updateCurrentStep = this.props.updateCurrentStep;
		this.handleNextButton = this.props.handleNextButton;
		this.handleBackButton = this.props.handleBackButton;
		this.i18n = this.props.i18n;
	}

	handleStepEvent = (event, data) => {
		switch (data.step.id) {
			case 0:
				this.setState({
					completedSteps: [],
					disabledSteps: footerSteps.slice(0),
					selectedStep: footerSteps[0],
					backButtonEnabled: false,
					nextButtonEnabled: true,
					hideBackButton: true,
					nextButtonText: this.i18n.get('test_send_footer_next')
				});
				this.updateCurrentStep(STEPS.TEST_METHOD);
				break;
			case 1:
				this.setState({
					backButtonEnabled: true,
					nextButtonEnabled: true,
					completedSteps: footerSteps.slice(0, 1),
					disabledSteps: footerSteps.slice(1),
					selectedStep: footerSteps[1],
					nextButtonText: this.i18n.get('test_send_footer_next'),
					hideBackButton: false
				});
				this.updateCurrentStep(STEPS.TEST_INPUT);
				break;
			case 2:
				this.setState({
					backButtonEnabled: true,
					nextButtonEnabled: false,
					completedSteps: footerSteps.slice(0, 2),
					disabledSteps: footerSteps.slice(2),
					selectedStep: footerSteps[2],
					nextButtonText: this.i18n.get('test_send_footer_next'),
					hideBackButton: false
				});
				this.updateCurrentStep(STEPS.SELECT_DEVICE);
				break;
			default:
		}
	};

	updateFooterState = (nextState) => {
		this.setState(nextState);
	};

	// Adds extra step to the footer when ContactKey Step is chosen to allow for select devices
	// We add it to the 2nd position on the 0-indexed array.
	// We remove it when not needed
	handleSelectDeviceStep = (contactId) => {
		if (contactId === CONTACT_ID.CONTACT_KEY && footerSteps.length === 3) {
			footerSteps.splice(2, 0, ({ id: 2, label: '' }));
		}	else if (contactId !== CONTACT_ID.CONTACT_KEY && footerSteps.length === 4) {
			footerSteps.splice(2, 1);
		}

		this.updateFooterState({
			steps: footerSteps
		});
	};

	render () {
		return (
			<div className="progress-modal-footer">
				<Button
					className={this.state.hideBackButton ? 'progress-back hide-visibility' : 'progress-back'}
					label={this.i18n.get('test_send_footer_back')}
					variant="outline-brand"
					onClick={this.handleBackButton}
					disabled={!this.state.backButtonEnabled}
				/>
				<ProgressIndicator
					id="progress-indicator"
					completedSteps={this.state.completedSteps}
					disabledSteps={this.state.disabledSteps}
					steps={this.state.steps}
					variant="modal"
					selectedStep={this.state.selectedStep}
					onStepClick={this.handleStepEvent}
				/>
				<Button
					className="progress-next"
					label={this.state.nextButtonText}
					variant="brand"
					onClick={this.handleNextButton}
					disabled={!this.state.nextButtonEnabled}
				/>
			</div>
		);
	}
}

export default ProgressFooter;
