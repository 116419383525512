const initialState = {
	mobileApps: [],
	mobileAppDetail: {},
	selectedMobileApp: [],
	mobilePushReportingName: '',
	customKeys: [],
	interactiveNotifications: [],
	selectedNotificationSound: 'default',
	selectedInteractiveNotification: [],
	isIOSBadgeToggleEnabled: false,
	isSendWindowToggleEnabled: false,
	selectedCampaign: {},
	sendWindowStartTime: '',
	sendWindowEndTime: '',
	sendWindowTimeZone: {},
	isCopyToInboxToggleEnabled: false,
	copyToInboxEndDate: '',
	copyToInboxEndTime: '',
	copyToInboxTimeZone: {}
};

export default initialState;
