export const getSoundLabel = (sound, i18n) => {
	let label = '';
	if (sound === 'default') {
		label = i18n.get('notification_sound_options_default');
	} else if (sound === '') {
		label =  i18n.get('none');
	} else if (sound?.length) {
		label =  sound; // custom sound
	}
	return label;
};

export const getSounds = (messageDefinition, i18n) => {
	try {
		const { content } = messageDefinition || {};
		const { customBlockData } = content?.views?.push?.meta?.options || {};
		const { androidSound, iOSSound } = customBlockData || {};
		const assetSounds = {};
		if (androidSound !== undefined && androidSound !== null) {
			assetSounds.androidSound = { label: getSoundLabel(androidSound, i18n), value: androidSound };
		}
		if (iOSSound !== undefined && iOSSound !== null) {
			assetSounds.iOSSound = { label: getSoundLabel(iOSSound, i18n), value: androidSound };
		}
		return Object.keys(assetSounds).length > 0 ? assetSounds : undefined;
	} catch (e) {
		console.log(e);
		return undefined;
	}
};
