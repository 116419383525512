/**
 * Utility to parse the asset's customBlockData to figure out if the message has carousel content
 * @param {*} messageDefinition selected message
 * @returns boolean true if the message has carousel content
 */
const isMessageCarousel = (messageDefinition) => {
	try {
		const { content } = messageDefinition || {};
		const { customBlockData } = content?.views?.push?.meta?.options || {};
		const { richFeatures } = customBlockData || {};
		return richFeatures?.viewTemplate?.type === '0'; // "0" = CAROUSEL
	} catch (e) {
		console.log(e);
		return false;
	}
};

export default isMessageCarousel;
